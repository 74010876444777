import * as React from 'react';
import * as firebase from "firebase/app";
import { ChartContext } from '../chart-context';
import CanvasJSReact from '../canvasjs/canvasjs.react';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export class ChartCard extends React.Component {
  state = {
    data: [],
    currentSensor: ''
  }

  static contextType = ChartContext;

  updateData() {
    const db = firebase.firestore();
    let data = new Array();

    db.collection("SensorData").where('Sensor', '==', this.context.sensor)
      .orderBy('Timestamp', 'desc').limit(240)
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let sensor = doc.data();
          let timestamp = new Date(sensor['Timestamp']['seconds']*1000);

          data.push({x: timestamp, y: sensor['Value']});
        });

        this.setState({data: data.reverse()});
      });
  }

  async componentDidMount(){
    this.state.currentSensor = this.context.sensor;
    this.updateData();
  }

  componentDidUpdate() {
    if (this.state.currentSensor !== this.context.sensor) {
      this.state.currentSensor = this.context.sensor;
      this.updateData();
    }
  }

  render() {
    const options = {
      title: {
        text: "Sensor data for " + this.state.currentSensor + " sensor"
      },
      data: [{
        type: "line",
        dataPoints: this.state.data
      }]
   }
   return <div className="card">
      <CanvasJSChart options = {options}/>
      </div>;
  }
}
