import * as React from "react";
import * as firebase from "firebase/app";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SensorData } from './SensorData'

const displayNames = {
  'red' : 'Mostly Wild',
  'blue' : 'Mostly Walkers',
  'black' : 'Garage (DS18B20)',
  'garageHumidity' : 'Garage Humidity',
  'garageTemperature' : 'Garage Temperature'
}

type sensorData = {
  sensor: string;
  type: string;
  timestamp: string;
  value: string;
}


export class CurrentValues extends React.Component<{}, {}> {

  state = {
    red: {
      sensor: 'red',
      name: 'red',
      type: '',
      timestamp: '',
      value: 0,
    },
    blue: {
      sensor: 'blue',
      name: 'blue',
      type: '',
      timestamp: '',
      value: 0,
    },
    black: {
      sensor: 'black',
      name: 'black',
      type: '',
      timestamp: '',
      value: 0,
    },
    garageHumidity: {
      sensor: 'garageHumidity',
      name: 'garageHumidity',
      type: '',
      timestamp: '',
      value: 0,
    },
    garageTemperature: {
      sensor: 'garageTemperature',
      name: 'garageTemperature',
      type: '',
      timestamp: '',
      value: 0,
    },
  }


  async componentDidMount(){
    const db = firebase.firestore();

    Object.keys(this.state).forEach(sensorKey => {
      db.collection("SensorData").where('Sensor', '==', sensorKey)
        .orderBy('Timestamp', 'desc').limit(1)
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let sensor = doc.data();
            let timestamp = new Date(sensor['Timestamp']['seconds']*1000);
            this.setState({[sensorKey]: {
              sensor: sensor['Sensor'],
              name: (displayNames as any)[sensorKey],
              type: sensor['Type'],
              timestamp: timestamp.toDateString() + " " + timestamp.toLocaleTimeString(),
              value: sensor['Value']
            }});
          });
        });
    });
  }

  render() {
    return <div>
      <Row>
          <Col>
            <SensorData
              sensor={this.state.red['sensor']}
              name={this.state.red['name']}
              type={this.state.red["type"]}
              timestamp={this.state.red["timestamp"]}
              value={this.state.red["value"]} />
          </Col>
          <Col>
            <SensorData
              sensor={this.state.blue['sensor']}
              name={this.state.blue['name']}
              type={this.state.blue["type"]}
              timestamp={this.state.blue["timestamp"]}
              value={this.state.blue["value"]} />
          </Col>
          <Col>
            <SensorData
              sensor={this.state.black['sensor']}
              name={this.state.black['name']}
              type={this.state.black["type"]}
              timestamp={this.state.black["timestamp"]}
              value={this.state.black["value"]} />
          </Col>
          </Row>
      <Row>
          <Col>
            <SensorData
              sensor={this.state.garageHumidity['sensor']}
              name={this.state.garageHumidity['name']}
              type={this.state.garageHumidity["type"]}
              timestamp={this.state.garageHumidity["timestamp"]}
              value={this.state.garageHumidity["value"]} />
          </Col>
          <Col>
            <SensorData
              sensor={this.state.garageTemperature['sensor']}
              name={this.state.garageTemperature['name']}
              type={this.state.garageTemperature["type"]}
              timestamp={this.state.garageTemperature["timestamp"]}
              value={this.state.garageTemperature["value"]} />
          </Col>
      </Row>
    </div>;
  }
}
