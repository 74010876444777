import React, { useState } from 'react';
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Container from 'react-bootstrap/Container';
import './App.css';
import { ChartContext } from './chart-context';
import { CurrentValues } from './components/CurrentValues';
import { ChartCard } from './components/ChartCard';

var firebaseConfig = {
    apiKey: "AIzaSyDsCFHbE0uES0HSR0WUl68WcWJXk0FyJbM",
    authDomain: "chezzy-cider.firebaseapp.com",
    databaseURL: "https://chezzy-cider.firebaseio.com",
    projectId: "chezzy-cider",
    storageBucket: "chezzy-cider.appspot.com",
    messagingSenderId: "439738278422",
    appId: "1:439738278422:web:98635477e88f75f543cb37",
    measurementId: "G-DL46BG8TP0"
  };

firebase.initializeApp(firebaseConfig);


const App = () => {
  const [sensor, setSensor] = useState("red");
  const value = { sensor, setSensor };

  return (
    <Container>
      <h1>Chezzy Cider</h1>
      <ChartContext.Provider value={value}>
        <CurrentValues />
        <br/>
        <ChartCard/>
      </ChartContext.Provider>
    </Container>
  );
}

export default App;
