import React, { useContext } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { ChartContext } from '../chart-context';

export interface SensorProps {
    sensor: string;
    name: string;
    type: string;
    value: number;
    timestamp: string;
}

export class SensorData extends React.Component<SensorProps, {}> {
    static contextType = ChartContext;

    render() {

      switch (this.props.type) {
        case 'temp':
          return<Card
            bg='dark'
            text='light' >
            <Card.Body>
              <Card.Title>{this.props.name}</Card.Title>
              <Card.Body>
                  <h1>{this.props.value}ºC</h1>
              </Card.Body>
              <Button
                variant="light"
                onClick={() => this.context.setSensor(this.props.sensor)}>See History</Button>
              <Card.Footer>
                <small className="text-muted">{this.props.timestamp}</small>
              </Card.Footer>
            </Card.Body>
          </Card>;
        case 'humidity':
          return<Card
            bg='dark'
            text='light' >
            <Card.Body>
              <Card.Title>{this.props.name}</Card.Title>
              <Card.Body>
                  <h1>{this.props.value}%</h1>
              </Card.Body>
              <Button
                variant="light"
                onClick={() => this.context.setSensor(this.props.sensor)}>See History</Button>
              <Card.Footer>
                <small className="text-muted">{this.props.timestamp}</small>
              </Card.Footer>
            </Card.Body>
          </Card>;
        default:
            return <p>Sensor type {this.props.type} not supported.</p>;
      }
    }
}
